.login{
  height: max(100vh, 30rem);
  background-image: url("./assets/images/bkg.jpg");
  background-position: center center;
  background-size: cover;
}

.uppy-DragDrop-container {
  border: 2px dashed #ec4d33 !important;
  border-radius: 2rem !important;
}

.uppy-StatusBar, .uppy-StatusBar::before {
  border: none !important;
  background: transparent !important;
}

.uppy-StatusBar-actionBtn--upload {
  background-color: #ec4d33 !important;
}

.uppy-StatusBar-actions {
  background: transparent !important;
}
